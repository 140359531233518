import React from 'react';
import PropTypes from 'prop-types';
import compose from '../../utils/compose/compose.js';

import withLocalizedContent from '../../language/withLocalizedContent';
import withTheme from '../../theme/withTheme';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';

import styles from '../SiteHeader/SiteHeader.scss';
import localStyles from './NGSSiteHeader.scss';

import { SiteHeader, connectRedux } from '../SiteHeader/SiteHeader';
import NGSLogo from '../NGSLayout/NGSLogo/NGSLogo';
import Link from '../../components/Link/Link';
import SVGIcon, { GLYPHS_NGS } from '../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../components/Button/Button.js';

const IDP_URL_OPTIONS = {
  uk: {
    teacher: { idpType: 'idp', platform: 'NGS_UK' },
    student: { idpType: 'idp2', platform: 'NGS_UK' }
  },
  kb: {
    teacher: { idpType: 'idp', platform: 'NGS_UK' },
    student: { idpType: 'idp2', platform: 'NGS_UK' }
  },
  anz: {
    teacher: { idpType: 'idp', platform: 'NGS_ANZ' },
    student: { idpType: 'idp', platform: 'NGS_ANZ' }
  },
  elt: {
    teacher: { idpType: 'idp', platform: 'CES' },
    student: { idpType: 'idp', platform: 'CES' }
  },
  default: {
    teacher: { idpType: 'idp', platform: 'CES' },
    student: { idpType: 'idp', platform: 'CES' }
  }
};

function getIdpOptions() {
  return IDP_URL_OPTIONS[localStorage.getItem('region')] || IDP_URL_OPTIONS.default;
}

class NGSSiteHeader extends SiteHeader {
  static propTypes = {
    ...SiteHeader.propTypes,
    themeOptions: PropTypes.object
  };

  _renderLogo = () =>
    /^\/ngs\/courses\//.test(window.location.pathname) ? (
      <Link to="/ngs" className={styles.courseHomeLink} aria-label="Home page">
        <SVGIcon glyph={GLYPHS_NGS.HOME} />
      </Link>
    ) : (
      <NGSLogo />
    );

  _renderSearchLink = () => (
    <li className={styles.searchButton}>
      <Link to="/search" role="button">
        <SVGIcon glyph={GLYPHS_NGS.SEARCH} />
        <span className="a11y-hide">Search</span>
      </Link>
    </li>
  );

  // hardcoded for now
  _renderNotificationsButton = () => {
    const { userSignedIn } = this.props;
    if (!userSignedIn) return null;
    return (
      <li className={styles.notificationsButton}>
        <a id="navHeader-notificationsButton" className={localStyles.notificationsButtonLink} role="button">
          <SVGIcon glyph={GLYPHS_NGS.ICON_BELL} />
          <span className="indicator">4</span>
        </a>
      </li>
    );
  };

  _renderUnauthorizedUserButtons = () => {
    const {
      userSignedIn,
      hideRegisterLink,
      hideSignInLink,
      localizedContent: { siteHeaders: content }
    } = this.props;

    if (userSignedIn) return null;

    const idpUrlOptions = getIdpOptions();
    console.log('IDP: ', idpUrlOptions);

    return (
      <li className={styles.loginButton}>
        {!hideRegisterLink ? (
          <Button
            id="register-button"
            type={buttonTypes.GHOST_INVERTED}
            glyph={GLYPHS_NGS.PROFILE}
            disableExternalBehaviour
            to={content.registerButton_link_url}
            text={content.registerButton_link_text}
            dataTestId="GOTO_REGISTER"
          />
        ) : null}
        {!hideSignInLink ? (
          <Button
            id="signIn"
            type={buttonTypes.GHOST_INVERTED}
            glyph={GLYPHS_NGS.PROFILE}
            text={content.sign_in_teacher_text}
            onClick={async () => {
              const url = await getIdpLoginUrl(null, { ...idpUrlOptions.teacher, targetUrl: `${location.origin}/ngs` });
              location.href = url;
            }}
            disableExternalBehaviour
            dataTestId="GOTO_LOGIN"
          />
        ) : null}
        {!hideSignInLink ? (
          <Button
            id="signIn"
            type={buttonTypes.GHOST_INVERTED}
            glyph={GLYPHS_NGS.PROFILE}
            text={content.sign_in_student_text}
            onClick={async () => {
              const url = await getIdpLoginUrl(null, { ...idpUrlOptions.student, targetUrl: `${location.origin}/ngs` });
              location.href = url;
            }}
            disableExternalBehaviour
          />
        ) : null}
      </li>
    );
  };

  render() {
    const {
      themeOptions,
      localizedContent: { siteHeaders: content }
    } = this.props;

    return (
      <header className={styles.siteHeader} style={{ backgroundColor: themeOptions.topBarColor }}>
        {/* For keyboard & screenreader users: (Hidden accessibly. Visible on focus) */}
        <a className="a11y-hide-focusable" href="#maincontent">
          {content.skip_to_main_content}
        </a>

        <div className="grid">
          <div className="row">
            <div id="navHeader" className="col">
              <nav aria-label="Primary">
                <ul>
                  {/* OUP Logo */}
                  <li className={styles.oxfordLogo}>{this._renderLogoLink()}</li>

                  {/* {this._renderSearchLink()} */}
                  <div className={localStyles.pushRight} />

                  {this._renderNotificationsButton()}

                  {/* Support link (Note this is replicated in SubHeader menu for xs devices:) */}
                  {/* Also note that this is hidden by CSS when the BurgerMenu is visible. */}
                  {this._renderHelpLink()}

                  {this._renderUnauthorizedUserButtons()}
                  {this._renderAccountWidget('ngs')}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default compose(withLocalizedContent('siteHeaders'), withTheme, connectRedux)(NGSSiteHeader);
