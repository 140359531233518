import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import styles from './NGSLayout.scss';

import cmsContent from '../../utils/cmsContent.js';
import Feature from '../../environment/Feature';
import USER_ROLES, { validUserRoles, roleIsAtLeast } from '../../globals/userRoles';

import NotFoundView from '../../views/errors/NotFoundView';
import ConnectedBannerInvitation from '../../components/BannerInvitation/ConnectedBannerInvitation.js';
import HeadersAndBanners from '../HeadersAndBanners/HeadersAndBanners.js';
import SessionExpiredPopup from '../SessionExpiredPopup/SessionExpiredPopup.js';
import SitewideProductPopup from '../SitewideProductPopup/SitewideProductPopup.js';
import NGSSiteHeader from '../NGSSiteHeader/NGSSiteHeader';
import NGSSubHeader from './NGSSubHeader/NGSSubHeader';
import NGSCourseSubHeader from './NGSCourseSubHeader/NGSCourseSubHeader';

// This structural component wraps the current react-router component
// in a page layout containing the SiteHeader

const NGSLayout = ({ embedded, children, userRole, orgId, isAtLeastTeacher }) => {
  const CMS = cmsContent.appLayout || {};

  const isDemoLoginPage = /^\/ngs-demo/.test(window.location.pathname);
  const isCoursePage = /^\/ngs\/courses\//.test(window.location.pathname);
  const isCESPage = [/^\/ngs\/myProfile/, /^\/ngs\/mySchool/, /^\/ngs\/org/].some((regex) => regex.test(window.location.pathname));
  const userAcceptInvitationInProgress = userRole === 'USER';

  const customSubHeader = isCoursePage ? <NGSCourseSubHeader /> : <NGSSubHeader />;

  return (
    <div id="app" className={classnames(styles.app, isCESPage ? styles.white : '')}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={CMS.pageTitle || 'Loading'}
        titleTemplate={CMS.titleTemplate || '%s | Oxford University Press'}
      />
      <div>
        <HeadersAndBanners
          customSiteHeaderComponent={NGSSiteHeader}
          customSubHeader={customSubHeader}
          isEmbedded={embedded}
          hideSubHeader={isDemoLoginPage || userAcceptInvitationInProgress}
          hideRegisterLink={isDemoLoginPage}
          hideSignInLink={isDemoLoginPage}
          hideWidgetDropDown={isDemoLoginPage}
          hideHelpLink={isDemoLoginPage}
          disableLogoLink
          myOrgUrl={isAtLeastTeacher ? '/ngs/mySchool' : '/ngs'}
          activityOrgUrl={`/ngs/org/${orgId}/activity`}
          orgChangeUrl="/ngs"
        />
        <div
          className={classnames(styles.pageContainer, {
            [styles.pageContainerEmbedded]: embedded,
            [styles.pageContainerWithoutNavbar]: isDemoLoginPage
          })}
        >
          <ConnectedBannerInvitation urlPrefix="/ngs" />
          <main id="maincontent">
            <Feature name="ngs-features" render={() => children} renderFallback={NotFoundView} />
            <SitewideProductPopup />
            <SessionExpiredPopup />
          </main>
        </div>
      </div>
    </div>
  );
};

NGSLayout.propTypes = {
  embedded: PropTypes.bool.isRequired,
  children: PropTypes.any,
  userRole: PropTypes.oneOf(validUserRoles),
  orgId: PropTypes.string.isRequired,
  isAtLeastTeacher: PropTypes.bool.isRequired
};

export default compose(
  connect(state => {
    const {
      identity: { missingFields, role: userRole }
    } = state;

    return {
      userRole,
      orgId: state.identity.currentOrganisationId,
      isAtLeastTeacher: roleIsAtLeast(USER_ROLES.TEACHER, state.identity.role)
    };
  })
)(NGSLayout);
