import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../utils/withRouter';
import { validUserRoles } from '../globals/userRoles';

import LoadingPage from '../structure/StatePages/LoadingPage/LoadingPage';
import SmartWelcomePage from './development/Smart/SmartWelcomePage/SmartWelcomePage';
import SmartRoot from '../structure/SmartRoot/SmartRoot';

class SmartIndexRedirectHandler extends Component {
  componentWillMount() {
    const { appReady } = this.props;
    if (appReady) this.redirectToCorrectPage();
  }

  componentWillReceiveProps(nextProps) {
    const { appReady } = this.props;
    if (!appReady && nextProps.appReady) {
      this.redirectToCorrectPage();
    }
  }

  redirectToCorrectPage() {
    const { router, userRole } = this.props;
    const region = localStorage.getItem('region');

    if (!region) {
      router.replace('/smart-demo');
    }

    // user hasn't accepted invitation yet
    if (userRole === 'USER') {
      router.replace('/smart/myProfile');
    }
  }

  render() {
    const { appReady, isAuthorized, routes } = this.props;

    if (!appReady) {
      return <LoadingPage showHeader={false} />;
    }

    return <SmartRoot routes={routes}>{isAuthorized ? <div>Smart Home Page</div> : <SmartWelcomePage />}</SmartRoot>;
  }
}

SmartIndexRedirectHandler.propTypes = {
  router: PropTypes.object.isRequired,
  appReady: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  userRole: PropTypes.oneOf(validUserRoles),
  routes: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default connect(state => ({
  appReady: state.app.appReady,
  isAuthorized: !!state.identity.role,
  userRole: state.identity.role
}))(withRouter(SmartIndexRedirectHandler));
